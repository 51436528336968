import React , {Suspense} from 'react';
const VirtualsIframe = React.lazy(() => import('./virtuals'));

/* USED TO POPULATE LOGO ID AND ITEM # IF NONE GIVEN
        virt=6979344869&item=ADV800S  */

export default function VirtsNav() {
    const currentVirt=typeof window.location.search!=='undefined'&&/virt=([0-9]{0,10})/i.test(window.location.search)
        ?window.location.search.toString().toLowerCase().match(/virt=([0-9]{0,10})/)[1]
        :'6979344869';
    const currentItem=typeof window.location.search!=='undefined'&&/item=([0-9A-Za-z]{0,10})/i.test(window.location.search)
        ?window.location.search.toString().toLowerCase().match(/item=([0-9A-Za-z]{0,10})/)[1]
        :'ADV800S';
    return (
        currentVirt||currentItem
        ?
        <Suspense fallback={<div>Loading...</div>}>
            <VirtualsIframe
                qBuk={'STARTS/QUEUE/T0'}
                url_virt={currentVirt}
                url_item={currentItem}
            />
        </Suspense>
        :
        null
    )
}
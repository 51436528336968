import React from 'react';
import VirtsNav from './components/virtapi/nav';
import './App.css'

function App() {
    return ( 
        <div className="App" >
            <VirtsNav />
        </div>
    );
}

export default App;